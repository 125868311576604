(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  const menuTrigger = document.querySelector('.main-navigation-trigger');
  const header = document.querySelector('.main-header');
  const menu = document.querySelector('.main-navigation');
  const icon = document.querySelector('.main-navigation-trigger__icon');

  function handleDOMContentLoaded() {
    if (menuTrigger) {
      menuTrigger.addEventListener('click', handleMainNavigationTrigger)
    }
  }

  function handleMainNavigationTrigger(e) {
    e.preventDefault();

    if (menu.classList.contains('main-navigation--visible')) {
      menu.classList.remove('main-navigation--visible');
      header.classList.remove('main-header--inverted');
      icon.classList.remove('main-navigation-trigger__icon--active');
    } else {
      menu.classList.add('main-navigation--visible');
      header.classList.add('main-header--inverted');
      icon.classList.add('main-navigation-trigger__icon--active');
    }
  }
})();